import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ghostImage from './ghost.png';
import './App.css';
import cat from "./catttt.png";
import abobo from "./abobora.png";

const GHOST_COUNT = 20;
const GHOST_SIZE = 60;

function App() {
  const [ghosts, setGhosts] = useState([]);
  const [tokenData, setTokenData] = useState(null);
  const [volume, setVolume] = useState(0.5);
  const requestRef = useRef();
  const audioRef = useRef(new Audio("/musicaghost.mp3"));

  useEffect(() => {
    // Configura a música para tocar em loop e ajustar o volume
    const audio = audioRef.current;
    audio.loop = true;
    audio.volume = volume;

    // Tenta iniciar a música automaticamente ao carregar
    audio.play().catch((error) => {
      console.error("Failed to play audio automatically:", error);
      // Se o navegador bloquear, adiciona um listener para iniciar com a interação do usuário
      document.addEventListener('click', playOnInteraction);
    });

    // Limpeza para remover o listener quando o componente desmontar
    return () => {
      audio.pause();
      document.removeEventListener('click', playOnInteraction);
    };
  }, [volume]);

  const playOnInteraction = () => {
    audioRef.current.play();
    document.removeEventListener('click', playOnInteraction); // Remove o listener após a interação
  };

  useEffect(() => {
    // Fetch token data from the API
    axios.get('https://apitoreturnca.onrender.com/api/purchaseData')
      .then(response => {
        setTokenData(response.data);
      })
      .catch(error => {
        console.error("Error fetching token data:", error);
      });
  }, []);

  useEffect(() => {
    // Initialize ghosts with random positions and speeds
    const initialGhosts = [];
    for (let i = 0; i < GHOST_COUNT; i++) {
      initialGhosts.push({
        id: i,
        x: Math.random() * (window.innerWidth - GHOST_SIZE),
        y: Math.random() * (window.innerHeight - GHOST_SIZE),
        dx: (Math.random() - 0.5) * 1,
        dy: (Math.random() - 0.5) * 1,
        speed: Math.random() * 2 + 1,
        width: GHOST_SIZE,
        height: GHOST_SIZE,
      });
    }
    setGhosts(initialGhosts);
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, []);

  const animate = () => {
    setGhosts((prevGhosts) => {
      const newGhosts = prevGhosts.map((ghost) => {
        let { x, y, dx, dy, speed } = ghost;

        x += dx * speed;
        y += dy * speed;

        if (x <= 0 || x + GHOST_SIZE >= window.innerWidth) dx *= -1;
        if (y <= 0 || y + GHOST_SIZE >= window.innerHeight) dy *= -1;

        return { ...ghost, x, y, dx, dy };
      });

      for (let i = 0; i < newGhosts.length; i++) {
        for (let j = i + 1; j < newGhosts.length; j++) {
          const ghostA = newGhosts[i];
          const ghostB = newGhosts[j];

          if (isColliding(ghostA, ghostB)) {
            const tempDx = ghostA.dx;
            const tempDy = ghostA.dy;
            ghostA.dx = ghostB.dx;
            ghostA.dy = ghostB.dy;
            ghostB.dx = tempDx;
            ghostB.dy = tempDy;
          }
        }
      }

      return newGhosts;
    });

    requestRef.current = requestAnimationFrame(animate);
  };

  const isColliding = (a, b) => {
    return (
      a.x < b.x + b.width &&
      a.x + a.width > b.x &&
      a.y < b.y + b.height &&
      a.y + a.height > b.y
    );
  };

  const handleVolumeChange = (event) => {
    const newVolume = event.target.value / 100;
    setVolume(newVolume);
    audioRef.current.volume = newVolume;
  };

  return (
    <div className="App">
      <div style={{
        position: 'absolute',
        top: '10px',
        right: '10px',
        display: 'flex',
        alignItems: 'center'
      }}>
        <label htmlFor="volume" style={{ marginRight: '5px', color: '#fff' }}>Volume</label>
        <input
          id="volume"
          type="range"
          min="0"
          max="100"
          value={volume * 100}
          onChange={handleVolumeChange}
          style={{
            cursor: 'pointer',
            width: '100px',
            backgroundColor: '#333',
            accentColor: '#fff'
          }}
        />
      </div>

      {ghosts.map((ghost) => (
        <img
          key={ghost.id}
          src={ghostImage}
          alt="ghost"
          className="ghost"
          style={{
            left: ghost.x,
            top: ghost.y,
            transform: ghost.dx < 0 ? 'scaleX(-1)' : 'scaleX(1)',
          }}
        />
      ))}

      

      {tokenData && (
        <>
          <div className="env-bloco">
          <img className='logo' src={cat} alt="" />
            <div className="tokenName">
              {tokenData.tokenName}
            </div>
            <div className="but pumpLink">
              <a href={tokenData.link}>BUY</a>
            </div>
            <div className="env-but">
              <a href={tokenData.twitterLink} className="twitterLink">
                Twitter/X
              </a>
              <a href={tokenData.telegramLink} className="telegramLink">
                TELEGRAM
              </a>
            </div>
            <div className="tokenCa">
              {tokenData.tokenCA}
            </div>
            <div className='aa'>Disclaimer: This is not financial advice. Investing in $MIG token comes with inherent risks. Please conduct thorough research and understand that the value of your investment can fluctuate. We are not responsible for any losses incurred—only invest what you are prepared to lose.</div>
          </div>
        </>
      )}

      <img className='abo' src={abobo} alt="" />
      <img className='abol' src={abobo} alt="" />
      
    </div>
  );
}

export default App;
